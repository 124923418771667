<template>
  <div>
    <section class="hero is-hero-bar is-purple-hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item is-hero-content-item">
              <div style="padding: 0 2em">
                <h1 class="title is-spaced">支払管理</h1>
                <h3 class="subtitle">
                  <b>{{ subtitle }}</b>
                </h3>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="buttons">
              <router-link tag="button" class="button" :to="{ name: 'Payment' }"
                >支払管理トップへ</router-link
              >
              <router-link
                v-if="atSoftCheckins && tPaymentWork"
                tag="button"
                class="button"
                :to="{ name: 'PaymentWork' }"
                >計算結果へ</router-link
              >
              <router-link
                v-if="atPaymentWork"
                tag="button"
                class="button"
                :to="{ name: 'SoftCheckins' }"
                >実績管理へ</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <router-view :tPaymentWork="tPaymentWork" />
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { parsePaymentYm } from '@/util'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [BaseMixin],
  props: {
    pathPaymentYm: String,
  },
  computed: {
    ...mapGetters('storePayment', ['findTPaymentWork', 'findMInstructor']),
    paymentYmLabel () {
      const { date } = parsePaymentYm(this.pathPaymentYm)
      return date.format('YYYY年MM月締め')
    },
    tPaymentWork () {
      return this.findTPaymentWork(this.pathPaymentYm)
    },
    atSoftCheckins () {
      return this.$route.name === 'SoftCheckins'
    },
    atPaymentWork () {
      return this.$route.name === 'PaymentWork'
    },
    atPaymentInstructor () {
      return this.$route.name === 'PaymentInstructor'
    },
    subtitle () {
      const ret = this.paymentYmLabel
      if (this.atSoftCheckins) {
        return `${ret} 実績管理`
      }
      if (this.atPaymentWork || this.atPaymentInstructor) {
        return `${ret} 計算結果`
      }
      return ret
    }
  },
  methods: {
    ...mapActions('storePayment', ['fetchPaymentWorks'])
  },
  created () {
    this.fetchPaymentWorks().catch(this.handleError)
  }
}
</script>
